// apiService.ts
import axios from 'axios';
import { User, Item, Attribute, Image, ImageSize, Outfit, ArrangedItem } from './models/models';
import { msalInstance } from './msalConfig';
import { AuthenticationResult } from '@azure/msal-browser';
import { getApiServerUrlBase } from './env_config';
import { setUser } from './userState';

// Factory function that accepts the base URL
export const createApiService = (apiServerUrlBase: string) => {
    const axiosInstance = axios.create({
        baseURL: apiServerUrlBase,
        maxRedirects: 1, // Set the maximum number of redirects to follow
    });

    axiosInstance.interceptors.request.use(
        async (config) => {
            try {
                const account = msalInstance.getAllAccounts()[0];
                if (account) {
                    const tokenResponse: AuthenticationResult = await msalInstance.acquireTokenSilent({
                        scopes: ["openid", "profile"],  // Replace with the scopes your API requires
                        account: account,
                    });

                    if (tokenResponse && tokenResponse.idToken) { //todo this really should be accessToken, but it's not coming back in the response
                        config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
                    } else {
                        console.error('No access token found');
                        /* try for interactive later if silent fails
                        try {
                            const tokenResponse = await msalInstance.acquireTokenPopup(tokenRequest);
                            console.log("Access Token:", tokenResponse.accessToken);
                        } catch (error) {
                            console.error("Popup token acquisition failed:", error);
                        }
                        */
                    }
                } else {
                    console.error('No account found');
                }
            } catch (error) {
                console.error('Error acquiring token silently:', error);
                // Optionally handle token acquisition errors (e.g., redirect to login)
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );


    /*
    axiosInstance.interceptors.request.use(async request => {
        console.log('Starting Request', request);
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
            const tokenResponse: AuthenticationResult = await msalInstance.acquireTokenSilent({
                scopes: ["openid", "profile"],  // Replace with the scopes your API requires
                account: account,
            });
            request.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        } else {
            console.error('No account found');
        }
        return request;
    });
    */
    /*
    axiosInstance.interceptors.response.use(response => {
        console.log('Response:', response);
        return response;
    });
    */

    const fetchUserModel = async () => {
        try {
            const response = await axiosInstance.get('/api/me/');
            //TODO:  might cache in local storage
            //const jsonData = JSON.parse(response.data);
            const user = mapUserModelFromJson(response.data);
            return user;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    //TODO check local cache for data , if missing force sync otherwise go with asyn, and update cache
    const initLookupData = async () => {
        await fetchColors();
        await fetchBodyLocations();
        await fetchItemTypesByBodyLocations();
        await fetchOutfitAttributeCategories();
    }

    const fetchBodyLocations = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/body_location/');
            localStorage.setItem('bodyLocations', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    }

    const fetchItemTypesByBodyLocations = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/item_types_by_body_location/');
            localStorage.setItem('itemTypesByBodyLocation', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    }

    const fetchColors = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/colors/');
            localStorage.setItem('colors', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    }

    const fetchOutfitAttributeCategories = async () => {
        try {
            const response = await axiosInstance.get('/api/attributes/outfit_categories/');
            localStorage.setItem('outfit_categories', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.error('Error fetching outfit attribute categories:', error);
            throw error;
        }
    };

    const mapUserModelFromJson = (jsonData: any): User => {
        const user = new User(
            jsonData.id,
            jsonData.created,
            jsonData.updated,
            jsonData.username,
            jsonData.displayName,
            mapItems(jsonData.items),
            jsonData.outfits.map((outfit: any) => new Outfit(
                outfit.id,
                mapAttributes(outfit.attributes),
                outfit.compositeImageUrl ? apiServerUrlBase + outfit.compositeImageUrl : null,
                mapArrangedItems(jsonData.items, outfit.arrangedItems)
            )),
        );
        return user;
    }

    const mapOutfitItemsFromIds = (jsonItems: Item[], itemIds: string[]): Item[] => {
        //for each item id get the Item object from user.items
        //map all the items so we can get the full apiServerUrl for the images
        let userItems = mapItems(jsonItems);
        return userItems.filter(item => itemIds.includes(item.id));
    }

    const mapArrangedItems = (jsonItems: any[], arrangedItems: any[]): ArrangedItem[] => {
        const itemMap = new Map(jsonItems.map(item => [item.id, item]));

        return arrangedItems.map((arrangedItem: any) => {
            const baseItem = itemMap.get(arrangedItem.itemId);
            if (!baseItem) {
                throw new Error(`Item with id ${arrangedItem.itemId} not found`);
            }

            return {
                ...new Item(
                    baseItem.id,
                    mapAttributes(baseItem.attributes),
                    new Image(mapImageSize(baseItem.image.originalImage))
                ),
                x: arrangedItem.x,
                y: arrangedItem.y,
                zIndex: arrangedItem.zIndex,
                width: arrangedItem.width,
                height: arrangedItem.height,
                rotation: arrangedItem.rotation
            } as ArrangedItem;
        });
    }

    const mapItems = (items: any[]): Item[] => {
        return items.map((item: any) => new Item(
            item.id,
            mapAttributes(item.attributes),
            new Image(mapImageSize(item.image.originalImage))
        ));
    }

    const mapImageSize = (imageSizeJson: any): ImageSize => {
        return new ImageSize(
            imageSizeJson.height,
            imageSizeJson.width,
            apiServerUrlBase + imageSizeJson.url
        );
    }

    const mapAttributes = (attributes: any[]): Attribute[] => {
        return attributes.map((attr: any) => new Attribute(
            attr.key,
            attr.value
        ));
    }

    const updateItem = async (userId: string, item: Item) => {
        const formData = new FormData();

        //push the named attributes into array format for saving
        item.attributes = [];
        for (const key in item) {
            if (item.hasOwnProperty(key) && key !== 'attributes' && key !== 'image') {
                if (Array.isArray(item[key])) {
                    //This is pretty much just for the "color" attribute which is an array of strings
                    for (const value in item[key]) {
                        item.attributes.push(new Attribute(key, item[key][value]));
                    }
                } else {
                    item.attributes.push(new Attribute(key, item[key]));
                }
            }
        }
        formData.append('item', JSON.stringify(item));

        if (item.image.originalImage.url.startsWith('blob:')) {
            const response = await fetch(item.image.originalImage.url);
            const blob = await response.blob();
            formData.append('image', blob, 'image.png');
        }

        try {
            if (item.id != "") {
                const response = await axiosInstance({
                    method: 'patch',
                    url: `/api/users/${userId}/items/${item.id}/`,
                    data: formData
                });

                return response.data;
            }
            else {
                const response = await axiosInstance({
                    method: 'post',
                    url: `/api/users/${userId}/items/`,
                    data: formData
                });

                return response.data;
            }

        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }

    }

    const getUsers = () => {
        const data = localStorage.getItem('users');
        return data ? JSON.parse(data) : null;
    }

    const getColors = () => {
        const data = localStorage.getItem('colors');
        return data ? JSON.parse(data) : null;
    }

    const getBodyLocations = () => {
        const data = localStorage.getItem('bodyLocations');
        return data ? JSON.parse(data) : null;
    }

    const getOutfitAttributeCategories = () => {
        const data = localStorage.getItem('outfit_categories');
        return data ? JSON.parse(data) : null;
    }

    const getItemTypesByBodyLocation = () => {
        const data = localStorage.getItem('itemTypesByBodyLocation');
        return data ? JSON.parse(data) : null;
    }



    const getItemTypesForBodyLocation = (bodyLocation: string): string[] => {
        const data: string | null = localStorage.getItem('itemTypesByBodyLocation');

        // Check if data is null
        if (data !== null && bodyLocation != null) {
            // Parse the JSON data
            const jsonData: Record<string, Record<string, string>> = JSON.parse(data);

            // Find the top-level key that matches the bodyLocation
            const itemTypesObject: Record<string, string> | undefined = jsonData[bodyLocation];

            // Return an array of all the keys for the object in the value found
            return itemTypesObject ? Object.keys(itemTypesObject) : [];
        }

        // Handle the case when data is null
        return [];
    }

    const getItemSubTypesForBodyTypeAndItemType = (bodyType: string, itemType: string): string[] => {
        const data: string | null = localStorage.getItem('itemTypesByBodyLocation');

        if (data !== null && bodyType !== null && itemType !== null) {
            const jsonData: Record<string, Record<string, string[]>> = JSON.parse(data);
            const bodyTypeData: Record<string, string[]> | undefined = jsonData[bodyType];

            if (bodyTypeData) {
                const itemTypeData: string[] | undefined = bodyTypeData[itemType];
                return itemTypeData ? itemTypeData : [];
            }
        }

        return [];
    }

    //TODO:  create OutfitAttribute ts object separate from ItemAttribute?
    const submitCreateOutfit = async (userId: string, outfitItemIds: string[], attributes: Attribute[]) => {
        const attributesForForm = attributes.map(attr => `${attr.key}=${attr.value}`).join('&');
        let postData = new URLSearchParams({
            "items": JSON.stringify(outfitItemIds), // Assuming Flask expects a JSON string for items
            "attributes": JSON.stringify(attributes)
        });
        try {
            const response = await axiosInstance.post(`/api/users/${userId}/outfits/`, postData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    }

    const deleteOutfit = async (userId: string, outfitId: string) => {
        try {
            const response = await axiosInstance.delete(`/api/users/${userId}/outfits/${outfitId}/`);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    }

    const saveOutfit = async (userId: string, outfit: Outfit) => {
        try {
            const method = outfit.id ? 'put' : 'post';
            const url = outfit.id
                ? `/api/users/${userId}/outfits/${outfit.id}/`
                : `/api/users/${userId}/outfits/`;

            // Transform the arranged items to only include necessary properties
            const transformedOutfit = {
                ...outfit,
                arrangedItems: outfit.arrangedItems?.map(item => ({
                    itemId: item.id,
                    x: item.x,
                    y: item.y,
                    zIndex: item.zIndex,
                    width: item.width,
                    height: item.height,
                    rotation: item.rotation
                }))
            };

            const response = await axiosInstance({
                method,
                url,
                data: JSON.stringify(transformedOutfit),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }
    };

    //TODO:  create OutfitAttribute ts object separate from ItemAttribute?
    //export async function saveOutfitCompositeImage(userId: string, outfitId: string, dataUrl: string) {
    const saveOutfitCompositeImage = async (userId: string, outfitId: string, dataUrl: string, onSuccess: (response: any) => void) => {

        const formData = new FormData();

        const response = await fetch(dataUrl);
        const blob = await response.blob();
        formData.append('image', blob, 'image.png');
        try {
            axiosInstance.post(`/api/users/${userId}/outfits/${outfitId}/composite_image/`, formData)
                .then(response => {
                    onSuccess(response.data)
                })
                .catch(error => {
                    console.error('Error submitting composite image:', error);
                });

        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        } finally {
            setUser(await apiService.fetchUserModel());
        }

    }

    const generateTestCloset = async (userId: string) => {
        await axiosInstance.post(`/api/users/${userId}/gen_data/`)
        setUser(await apiService.fetchUserModel());
    }

    const resetCloset = async (userId: string) => {
        await axiosInstance.post(`/api/users/${userId}/reset_closet/`)
        setUser(await apiService.fetchUserModel());
    }

    // Return an object containing all API service functions
    return {
        fetchUserModel,
        initLookupData,
        fetchBodyLocations,
        fetchItemTypesByBodyLocations,
        fetchColors,
        mapUserModelFromJson,
        mapItems,
        mapAttributes,
        updateItem,
        getUsers,
        getColors,
        getBodyLocations,
        getItemTypesByBodyLocation,
        getItemTypesForBodyLocation,
        getItemSubTypesForBodyTypeAndItemType,
        submitCreateOutfit,
        deleteOutfit,
        saveOutfitCompositeImage,
        generateTestCloset,
        resetCloset,
        saveOutfit,
        getOutfitAttributeCategories
    };
};

export const apiService = createApiService(getApiServerUrlBase());
