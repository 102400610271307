<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>My Closet</ion-list-header>
            <ion-note>{{ userState.user ? `${userState.user?.displayName}'s` : 'Your' }} Fashion Friend</ion-note>
            <ion-menu-toggle :auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item
                v-if="(p.secure == false) || (p.secure && userState.isAuthenticated) || (p.unsecureOnly && !userState.isAuthenticated)"
                router-direction="root" :router-link="p.url" lines="none" :detail="false" class="hydrated"
                :class="{ 'selected': isSelected(p.url) }">
                <ion-icon aria-hidden="true" slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>

          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content">
      </ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { ref, reactive } from 'vue';
import {
  IonApp,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/vue';

import {
  bookmarkOutline,
  bookmarkSharp,
  shirtOutline,
  shirtSharp,
  bodyOutline,
  bodySharp,
  settingsOutline,
  settingsSharp,
  homeOutline,
  homeSharp,
  logOutOutline,
  logOutSharp,
  logInOutline,
  logInSharp,
} from 'ionicons/icons';

import { useRoute } from 'vue-router';
import { watch } from 'vue';
//import { User } from './models/models';
import { msalInstance } from './msalConfig';
import { setUser, userState, clearUser } from './userState';
import { apiService } from './apiService';

let appPages = ref([
  {
    title: 'Home',
    url: '/home',
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    secure: false,
  },
  {
    title: 'Closet',
    url: '/closet',
    iosIcon: shirtOutline,
    mdIcon: shirtSharp,
    secure: true,
  },
  {
    title: 'Outfits',
    url: '/outfits',
    iosIcon: bodyOutline,
    mdIcon: bodySharp,
    secure: true,
  },
  {
    title: 'Outfits2',
    url: '/outfits2',
    iosIcon: bodyOutline,
    mdIcon: bodySharp,
    secure: true,
  },
  {
    title: 'Settings',
    url: '/settings',
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
    secure: true,
  },
  {
    title: 'Log out',
    url: '/logout',
    iosIcon: logOutOutline,
    mdIcon: logOutSharp,
    secure: true,
  },
  {
    title: 'Log In',
    url: '/login',
    iosIcon: logInOutline,
    mdIcon: logInSharp,
    unsecureOnly: true,
  },

]);

const route = useRoute();
const isSelected = (url: string) => {
  return route.path === url;
};

onMounted(async () => {
  try {
    // Initialize MSAL
    await msalInstance.initialize();

    // Handle redirect response
    const authResult = await msalInstance.handleRedirectPromise();

    if (authResult) {
      msalInstance.setActiveAccount(authResult.account);

      setUser(await apiService.fetchUserModel());
    } else {
      const account = msalInstance.getActiveAccount();
      //clearUser();
      // Clear local storage and session storage
      //localStorage.clear();
      //sessionStorage.clear();
      // not sure what to do here
      //setUser(await apiService.fetchUserModel());

      //updateUserState(account);
    }
  } catch (error) {
    console.error('Authentication error:', error);
  }
  await apiService.initLookupData();

});

watch(
  () => userState.isAuthenticated,
  (newValue) => {
    if (!newValue) {
      // User has logged out, update UI accordingly
    }
  }
);

/*
onMounted(async () => {
  await apiService.initLookupData();

  userState.user = apiService.getUserModel();

  watch(authState, (newVal) => {
    console.log('isAuthenticated changed to: ' + newVal.isAuthenticated);
    if (newVal.isAuthenticated) {
      refreshUserContext();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      userState.user = null;
    }
    //refresh menu - maybe not needed now that authState is reactive?
    appPages.value = [...appPages.value];
  });
  
});
*/

</script>

<style scoped>
ion-app {
  --padding-top: var(--ion-safe-area-top);
}

ion-content {
  --padding-top: var(--ion-safe-area-top);
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
