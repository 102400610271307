import { msalInstance } from './msalConfig';
import { clearUser } from './userState';

export function login() {
    const loginRequest = {
        scopes: ['openid', 'profile'],
    };
    msalInstance.loginRedirect(loginRequest);
}

export function logout() {
    clearUser();
    localStorage.clear();
    msalInstance.logoutRedirect({
        //onRedirectNavigate: () => false, // Prevents default navigation
    });
}
