import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { login, logout } from '../auth';
import { userState } from '../userState';

// Extend the RouteRecordRaw interface
type AuthRouteRecordRaw = RouteRecordRaw & {
  requiresAuth?: boolean;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/closet',
    component: () => import('../views/ClosetPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/outfits',
    component: () => import('../views/OutfitsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/outfits2',
    component: () => import('../views/OutfitsPage2.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    component: () => import('../views/SettingsPage.vue')
  },
  {
    path: '/logout',
    component: { template: '<div>Logging out...</div>' },
    beforeEnter: (to, from, next) => {
      logout();
      next(false); // Prevent navigation to the /logout route
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    component: { template: '<div>Logging in...</div>' },
    beforeEnter: (to, from, next) => {
      login();
      next(false); // Prevent navigation to the /logout route
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/home',
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.requiresAuth);

  if (authRequired && !userState.isAuthenticated) {
    // If the user is not authenticated, go to home page
    //add exception for  /auth  and  /auth-callback  routes
    //next('/home');
    next();
  } else {
    // If the user is authenticated, proceed to the route
    next();
  }
});


export default router
