// env_config.ts

// Function to get API Server URL Base
export const getApiServerUrlBase = (): string => {
  const domain = import.meta.env.VITE_APP_API_SERVER_URL_DOMAIN;
  const scheme = import.meta.env.VITE_APP_API_SERVER_URL_SCHEME;
  const port = import.meta.env.VITE_APP_API_SERVER_URL_PORT;

  if (domain === 'PLACEHOLDER_INTERNAL_IP') {
    const origin = new URL(window.location.origin);
    const newUrl = `${scheme}://${origin.hostname}:${port}`;
    console.log("Constructed newUrl: ", newUrl);
    return newUrl;
  }
  console.log("Used baseUrl: ", `${scheme}://${domain}:${port}`);
  return `${scheme}://${domain}:${port}`;

};