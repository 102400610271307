import { PublicClientApplication, Configuration, AuthenticationResult } from "@azure/msal-browser";

const baseUrl = window.location.origin;

const msalConfig: Configuration = {
    auth: {
        clientId: "3baa435f-7f94-4d4e-aa51-d1d2a19bab77",
        //authority: "https://myclosetdev.b2clogin.com/myclosetdev.onmicrosoft.com/B2C_1_MyClosetSignUpSignInSmsFlow",
        //authority: "https://myclosetdev.b2clogin.com/myclosetdev.onmicrosoft.com/B2C_1_SignUpSignInEmailSmsFlow",
        authority: "https://myclosetdev.b2clogin.com/myclosetdev.onmicrosoft.com/B2C_1_MyClosetSignUpSignInSmsEmailFlow",
        redirectUri: `${baseUrl}/auth/callback`,
        knownAuthorities: ["myclosetdev.b2clogin.com"], // Add your tenant domain
        //postLogoutRedirectUri: `$baseUrl`,
    },
    cache: {
        cacheLocation: "localStorage", // Store tokens in local storage
        storeAuthStateInCookie: false, // Set to true if experiencing issues on IE11 or Edge
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

