import { reactive, watch } from 'vue';
import { User } from './models/models';

export const userState = reactive({
    isAuthenticated: false,
    user: null as User | null,
});

export function setUser(userData: User | null) {
    console.log('setUser:', userData);
    userState.isAuthenticated = true;
    userState.user = userData;
    localStorage.setItem('userState', JSON.stringify(userState));
}

export function clearUser() {
    console.log('clearUser');
    userState.isAuthenticated = false;
    userState.user = null;
    localStorage.setItem('userState', JSON.stringify(userState));
}

const storedUserState = localStorage.getItem('userState');
if (storedUserState) {
    const parsedState = JSON.parse(storedUserState);
    userState.isAuthenticated = parsedState.isAuthenticated;
    userState.user = parsedState.user;
    console.log('Rehydrated userState from localStorage:', userState);
}

watch(
    userState,
    (newState) => {
        localStorage.setItem('userState', JSON.stringify(newState));
    },
    { deep: true }
);